import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';

import * as serviceWorker from './serviceWorker';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { icons } from './assets/icons';
import store from './store';
import { RecoilRoot } from 'recoil';

React.icons = icons;

ReactDOM.render(
	<RecoilRoot>
		<Provider store={store}>
			<App />
		</Provider>
	</RecoilRoot>,
	document.getElementById('root')
);

serviceWorker.unregister();
